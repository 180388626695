import React, { useState, useEffect, useRef } from "react";
// import { Stepper, Step } from "react-form-stepper";
import Alert from "react-bootstrap/Alert";

// import moment from 'moment'
import dayjs from "dayjs";

import {
  isNationalIdentificationNumberValid, // 身分證字號
} from "taiwan-id-validator";

import { resizeFile } from "../../lib/imageResize";

import { TWCity } from "../../data/tw-city";

import { useHistory } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import Spinner from "react-bootstrap/Spinner";

import { ImageUploader, Toast, Image, Space, SpinLoading } from "antd-mobile";

import styles from "./KycValidForm.module.scss";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";

import {
  setKyc,
  cleanSetKycStatus,
  getKyc,
} from "../../store/actions/kycAction";

import {
  setKycUserData,
  cleanKycSetUserStatus,
  getKycUserData,
} from "../../store/actions/userAction";

/**
 * 1 => 審核中
 * 2 => 審核通過
 * 3 => 審核失敗
 */

const KycValidForm = () => {
  const history = useHistory();

  const { error: setKycError, data: kycData } = useSelector(
    (state) => state.setKyc
  );

  const {
    loading: userKycLoading,
    error: userKycError,
    data: userKycData,
  } = useSelector((state) => state.setUser);

  const { data: getKycData, isLoading: kycLoading } = useSelector(
    (state) => state.getKyc
  );

  const { data: getUserKycData, loading: userLoading } = useSelector(
    (state) => state.getUer
  );

  const { Data, Status } = getUserKycData || {};
  const { reqStatus } = Data || {};

  console.log(getKycData);

  const bankLastItem = getKycData?.[getKycData?.length - 1];
  const isValidIng = reqStatus === 1;
  const updateLevel = reqStatus === 3;
  const validSuccess = updateLevel
    ? true
    : Status === 101 && bankLastItem?.User_BankStatus === 101;

  const [bankData, setBankData] = useState({
    SType: null,
    P1: "",
    P2: "",
    P3: "",
    Img1: "",
  });

  const [bankStatus, setBankStatus] = useState({
    P1: null,
    P2: null,
    p3: null,
    Img1: null,
  });

  const [userData, setUserData] = useState({
    country: "台灣",
    idType: "初",
    name: "",
    email: "",
    validCode: "",
    birthday: "",
    IDNumber: "",
    city: "",
    area: "",
    address: "",
    idLocation: "台北",
    idDate: "",

    IDPhotoFront: "",
    IDPhotoBack: "",
    IDPhotoSecondFront: "",
    IDPhotoSecondBack: "",
    selfPhoto: "",
    sign: "",
  });

  const [idPhotoFront, setIdPhotoFront] = useState([]);
  const [idPhotoBack, setIdPhotoBack] = useState([]);
  const [IDPhotoSecondFront, setIDPhotoSecondFront] = useState([]);
  const [IDPhotoSecondBack, setIDPhotoSecondBack] = useState([]);
  const [selfList, setSelfList] = useState([]);
  const [accountList, setAccountList] = useState([]);
  const [signList, setSignList] = useState([]);

  const [kycValidUerStatus, setKycValidUserStatus] = useState({});
  const [kycValidUerDesc, setKycValidUserDesc] = useState({});

  console.log(getUserKycData, "origin all");

  useEffect(() => {
    if (!getUserKycData) return;

    const { Data } = getUserKycData || {};
    if (!Data) return;

    const { status, des, client, bankset, bank } = Data || {};

    if (des) {
      setKycValidUserDesc(des);
    }

    if (status) {
      setKycValidUserStatus(status);
    }

    if (client) {
      setUserData(client);
    }

    if (bankset) {
      setBankStatus(bankset);
    }

    if (bank) {
      setBankData(bank);
    }
  }, [getUserKycData]);

  const formBtnRef = useRef();
  const datePickerRef = useRef();
  const scrollRef = useRef();

  const dispatch = useDispatch();

  const scrollToRef = (ref) => {
    window.scrollTo({ top: ref.current.offsetTop, behavior: "smooth" });
  };

  const mockUpload = async (file, type) => {
    const result = await resizeFile(file);

    if (type === "Img1") {
      setBankData((prev) => ({
        ...prev,
        [type]: result,
      }));
    } else {
      setUserData((prev) => ({
        ...prev,
        [type]: result,
      }));
    }

    return {
      url: URL.createObjectURL(file),
    };
  };

  const beforeUpload = (file) => {
    if (isValidIng) return null;

    if (file.size > 4048 * 4048) {
      Toast.show("圖片尺寸超過4M");
      return null;
    }
    return file;
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();

    // const form = event.currentTarget;

    if (!isNationalIdentificationNumberValid(userData.IDNumber)) {
      Toast.show({
        icon: "fail",
        content: "無效的身分證號碼",
      });

      scrollToRef(scrollRef);
      return;
    }

    if (kycValidUerStatus?.IDPhotoFront === 3 && !idPhotoFront?.length) {
      Toast.show({
        icon: "fail",
        content: "請上傳驗證照片！",
      });
      return;
    }

    if (kycValidUerStatus?.IDPhotoBack === 3 && !idPhotoBack?.length) {
      Toast.show({
        icon: "fail",
        content: "請上傳驗證照片！",
      });
      return;
    }

    if (
      kycValidUerStatus?.IDPhotoSecondFront === 3 &&
      !IDPhotoSecondFront?.length
    ) {
      Toast.show({
        icon: "fail",
        content: "請上傳驗證照片！",
      });
      return;
    }

    if (
      kycValidUerStatus?.IDPhotoSecondBack === 3 &&
      !IDPhotoSecondBack?.length
    ) {
      Toast.show({
        icon: "fail",
        content: "請上傳驗證照片！",
      });
      return;
    }

    if (kycValidUerStatus?.selfPhoto === 3 && !selfList?.length) {
      Toast.show({
        icon: "fail",
        content: "請上傳驗證照片！",
      });
      return;
    }

    if (bankStatus.status?.Img === 3 && !accountList?.length) {
      Toast.show({
        icon: "fail",
        content: "請上傳驗證照片！",
      });
      return;
    }

    if (kycValidUerStatus?.sign === 3 && !signList?.length) {
      Toast.show({
        icon: "fail",
        content: "請上傳驗證照片！",
      });
      return;
    }

    const { Data } = getUserKycData || {};
    const { client, reqStatus, ...rest } = Data || {};

    const { P1, P2, P3, Img1 } = bankData || {};

    const sendUserData = {
      ...rest,
      client: { ...userData },
      bank: { ...bankData },
      reqStatus: 1,
    };

    dispatch(setKycUserData(sendUserData));
    dispatch(setKyc({ P1, P2, P3, Img1, SType: 1 }));
  };

  const onChange = (e) => {
    setBankData((prev) => ({
      ...prev,
      [e.target.id]: e.target.value,
    }));
  };

  const userDataOnChange = (e) => {
    setUserData((prev) => ({
      ...prev,
      [e.target.id]: e.target.value,
    }));
  };

  useEffect(() => {
    if (!setKycError) return;
    Toast.show({
      icon: "fail",
      content: setKycError,
    });
  }, [setKycError, userKycError]);

  useEffect(() => {
    if (!userKycError) return;
    Toast.show({
      icon: "fail",
      content: userKycError,
    });
  }, [userKycError]);

  useEffect(() => {
    if (!kycData || !userKycData) return;

    Toast.show({
      icon: "success",
      content: "上傳成功，頁面即將跳轉",
      afterClose: () => {
        history.replace("/home");
        dispatch(cleanKycSetUserStatus());
        dispatch(cleanSetKycStatus());
      },
    });
  }, [kycData, history, userKycData, dispatch]);

  useEffect(() => {
    dispatch(getKycUserData());
    dispatch(getKyc());
  }, [dispatch]);

  if (kycLoading || userLoading) {
    return (
      <Space
        style={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          paddingTop: "25rem",
        }}
      >
        <SpinLoading />
        <span style={{color: 'white'}}>loading...</span>
      </Space>
    );
  }

  return (
    <>
      <Card className={styles.container}>
        <div style={{ marginBottom: "1rem" }}>
          {!getUserKycData && (
            <Alert show variant="danger">
              尚未驗證
            </Alert>
          )}
          {isValidIng && (
            <Alert show variant="warning">
              資料已上傳，驗證中
            </Alert>
          )}

          {validSuccess && (
            <Alert show variant="success">
              已經完成驗證
            </Alert>
          )}
        </div>
        <div className={styles.header}>實名驗證-基本資料</div>
        <Form onSubmit={onSubmit} style={{ marginTop: "2rem" }}>
          <Form.Group className="mb-3" controlId="name">
            <Form.Label>姓名</Form.Label>
            <Form.Control
              required
              placeholder="輸入真實姓名"
              className="form-select mb-4 pl-3"
              onChange={userDataOnChange}
              value={userData.name}
              autoComplete="off"
              isInvalid={kycValidUerStatus?.name === 3}
              isValid={kycValidUerStatus?.name === 2}
              disabled={
                kycValidUerStatus?.name === 2 ||
                kycValidUerStatus?.name === 1 ||
                isValidIng
              }
              style={{
                borderColor: kycValidUerStatus?.name === 1 && "#d48806",
              }}
            />
            {kycValidUerDesc?.name && (
              <Form.Text style={{ marginTop: "-8px" }}>
                {kycValidUerDesc?.name}
              </Form.Text>
            )}
            {kycValidUerStatus?.name === 1 && (
              <div style={{ marginTop: "-8px", color: "#d48806" }}>驗證中</div>
            )}
          </Form.Group>

          <Form.Group className="mb-3" controlId="email">
            <Form.Label>電子信箱</Form.Label>
            <Form.Control
              type="email"
              required
              placeholder="輸入電子信箱"
              className="form-select mb-4 pl-3"
              onChange={userDataOnChange}
              value={userData.email}
              autoComplete="off"
              isInvalid={kycValidUerStatus?.email === 3}
              isValid={kycValidUerStatus?.email === 2}
              disabled={
                kycValidUerStatus?.email === 2 ||
                kycValidUerStatus?.email === 1 ||
                isValidIng
              }
              style={{
                borderColor: kycValidUerStatus?.email === 1 && "#d48806",
              }}
            />
            {kycValidUerStatus?.email === 1 && (
              <div style={{ marginTop: "-8px", color: "#d48806" }}>驗證中</div>
            )}
            {kycValidUerDesc?.email && (
              <Form.Text style={{ marginTop: "-8px" }}>
                {kycValidUerDesc?.email}
              </Form.Text>
            )}
          </Form.Group>

          <Form.Group className="mb-3" controlId="birthday">
            <Form.Label>出生日期</Form.Label>
            <Form.Control
              ref={datePickerRef}
              required
              type="date"
              placeholder="選擇出生日期"
              className="form-date mb-4 pl-3"
              onChange={userDataOnChange}
              value={userData.birthday}
              autoComplete="off"
              isInvalid={kycValidUerStatus?.birthday === 3}
              isValid={kycValidUerStatus?.birthday === 2}
              max={dayjs().subtract(20, "year").format("YYYY-MM-DD")}
              disabled={
                kycValidUerStatus?.birthday === 2 ||
                kycValidUerStatus?.birthday === 1 ||
                isValidIng
              }
              style={{
                borderColor: kycValidUerStatus?.birthday === 1 && "#d48806",
              }}

              // onBlur={onBlurHandle}
              // isInvalid={blurShowError.birthday}
            />

            {kycValidUerStatus?.birthday === 1 && (
              <div style={{ marginTop: "-8px", color: "#d48806" }}>驗證中</div>
            )}

            {kycValidUerDesc?.birthday && (
              <Form.Text style={{ marginTop: "-8px" }}>
                {kycValidUerDesc?.birthday}
              </Form.Text>
            )}
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>選擇國籍</Form.Label>
            <Form.Control
              required
              as="select"
              placeholder="選擇類別"
              className="form-select mb-4 pl-3"
              onChange={userDataOnChange}
              value={userData.country}
              id="country"
              isInvalid={kycValidUerStatus?.country === 3}
              isValid={kycValidUerStatus?.country === 2}
              disabled={
                kycValidUerStatus?.country === 2 ||
                kycValidUerStatus?.country === 1 ||
                isValidIng
              }
              style={{
                fontSize: "17px",
                borderColor: kycValidUerStatus?.country === 1 && "#d48806",
              }}
            >
              <option>台灣</option>
              <option>中國</option>
              <option>日本</option>
            </Form.Control>

            {kycValidUerStatus?.country === 1 && (
              <div style={{ marginTop: "-8px", color: "#d48806" }}>驗證中</div>
            )}
            {kycValidUerDesc?.country && (
              <Form.Text style={{ marginTop: "-8px" }}>
                {kycValidUerDesc?.country}
              </Form.Text>
            )}
          </Form.Group>

          {/* 身分證字號 */}
          <Form.Group className="mb-3" controlId="IDNumber">
            <Form.Label>身份證字號</Form.Label>
            <Form.Control
              ref={scrollRef}
              required
              placeholder="輸入身份證字號"
              className="form-select mb-4 pl-3"
              onChange={userDataOnChange}
              value={userData.IDNumber}
              autoComplete="off"
              // onBlur={onBlurHandle}
              isInvalid={kycValidUerStatus?.IDNumber === 3}
              isValid={kycValidUerStatus?.IDNumber === 2}
              disabled={
                kycValidUerStatus?.IDNumber === 2 ||
                kycValidUerStatus?.IDNumber === 1 ||
                isValidIng
              }
              style={{
                fontSize: "17px",
                borderColor: kycValidUerStatus?.IDNumber === 1 && "#d48806",
              }}
            />
            {kycValidUerStatus?.IDNumber === 1 && (
              <div style={{ marginTop: "-8px", color: "#d48806" }}>驗證中</div>
            )}
            {kycValidUerDesc?.IDNumber && (
              <Form.Text style={{ marginTop: "-8px" }}>
                {kycValidUerDesc?.IDNumber}
              </Form.Text>
            )}
          </Form.Group>

          {/* 發證日期 */}
          <Form.Group className="mb-3" controlId="idDate">
            <Form.Label>發證日期</Form.Label>
            <Form.Control
              required
              placeholder="選擇發證日期"
              className="form-date mb-4 pl-3"
              onChange={userDataOnChange}
              value={userData.idDate}
              autoComplete="off"
              isInvalid={kycValidUerStatus?.idDate === 3}
              isValid={kycValidUerStatus?.idDate === 2}
              disabled={
                kycValidUerStatus?.idDate === 2 ||
                kycValidUerStatus?.idDate === 1 ||
                isValidIng
              }
              style={{
                fontSize: "17px",
                borderColor: kycValidUerStatus?.idDate === 1 && "#d48806",
              }}
            />
            {kycValidUerStatus?.idDate === 1 && (
              <div style={{ marginTop: "-8px", color: "#d48806" }}>驗證中</div>
            )}
            {kycValidUerDesc?.idDate && (
              <Form.Text style={{ marginTop: "-8px" }}>
                {kycValidUerDesc?.idDate}
              </Form.Text>
            )}
          </Form.Group>

          {/* 發證地點 */}
          <Form.Group className="mb-3" controlId="idLocation">
            <Form.Label>發證地點</Form.Label>
            <Form.Control
              required
              as="select"
              placeholder="輸入發證地點"
              className="form-select mb-4 pl-3"
              onChange={userDataOnChange}
              value={userData.idLocation}
              autoComplete="off"
              isInvalid={kycValidUerStatus?.idLocation === 3}
              isValid={kycValidUerStatus?.idLocation === 2}
              disabled={
                kycValidUerStatus?.idLocation === 2 ||
                kycValidUerStatus?.idLocation === 1 ||
                isValidIng
              }
              style={{
                fontSize: "17px",
                borderColor: kycValidUerStatus?.idLocation === 1 && "#d48806",
              }}
            >
              {TWCity.map((city) => (
                <option>{city.name}</option>
              ))}
            </Form.Control>
            {kycValidUerStatus?.idLocation === 1 && (
              <div style={{ marginTop: "-8px", color: "#d48806" }}>驗證中</div>
            )}
            {kycValidUerDesc?.idLocation && (
              <Form.Text style={{ marginTop: "-8px" }}>
                {kycValidUerDesc?.idLocation}
              </Form.Text>
            )}
          </Form.Group>

          {/* 領補換類別 */}
          <Form.Group className="mb-3">
            <Form.Label>領補換類別</Form.Label>
            <Form.Control
              required
              as="select"
              placeholder="選擇類別"
              className="form-select mb-4 pl-3"
              onChange={userDataOnChange}
              value={userData.idType}
              isInvalid={kycValidUerStatus?.idType === 3}
              isValid={kycValidUerStatus?.idType === 2}
              disabled={
                kycValidUerStatus?.idType === 2 ||
                kycValidUerStatus?.idType === 1 ||
                isValidIng
              }
              style={{
                fontSize: "17px",
                borderColor: kycValidUerStatus?.idType === 1 && "#d48806",
              }}
              id="idType"
            >
              <option>初</option>
              <option>補</option>
              <option>換</option>
            </Form.Control>

            {kycValidUerStatus?.idType === 1 && (
              <div style={{ marginTop: "-8px", color: "#d48806" }}>驗證中</div>
            )}

            {kycValidUerDesc?.idType && (
              <Form.Text style={{ marginTop: "-8px" }}>
                {kycValidUerDesc?.idType}
              </Form.Text>
            )}
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>戶籍地址</Form.Label>

            <div className={styles["address-row"]}>
              <div className="mb-4" style={{ width: "100%" }}>
                <Form.Control
                  required
                  placeholder="請輸入城市"
                  className="form-select mb-4 pl-3"
                  onChange={userDataOnChange}
                  value={userData.city}
                  id="city"
                  autoComplete="off"
                  isInvalid={kycValidUerStatus?.city === 3}
                  isValid={kycValidUerStatus?.city === 2}
                  disabled={
                    kycValidUerStatus?.city === 2 ||
                    kycValidUerStatus?.city === 1 ||
                    isValidIng
                  }
                  style={{
                    fontSize: "17px",
                    borderColor: kycValidUerStatus?.city === 1 && "#d48806",
                  }}
                />

                {kycValidUerStatus?.city === 1 && (
                  <div style={{ marginTop: "-8px", color: "#d48806" }}>
                    驗證中
                  </div>
                )}
                {kycValidUerDesc?.city && (
                  <Form.Text style={{ marginTop: "-8px" }}>
                    {kycValidUerDesc?.city}
                  </Form.Text>
                )}
              </div>

              <div className="mb-4" style={{ width: "100%" }}>
                <Form.Control
                  required
                  placeholder="請輸入區域"
                  className="form-select mb-4 pl-3"
                  onChange={userDataOnChange}
                  value={userData.area}
                  id="area"
                  autoComplete="off"
                  isInvalid={kycValidUerStatus?.area === 3}
                  isValid={kycValidUerStatus?.area === 2}
                  disabled={
                    kycValidUerStatus?.area === 2 ||
                    kycValidUerStatus?.area === 1 ||
                    isValidIng
                  }
                  style={{
                    fontSize: "17px",
                    borderColor: kycValidUerStatus?.area === 1 && "#d48806",
                  }}
                />

                {kycValidUerStatus?.area === 1 && (
                  <div style={{ marginTop: "-8px", color: "#d48806" }}>
                    驗證中
                  </div>
                )}
                {kycValidUerDesc?.area && (
                  <Form.Text style={{ marginTop: "-8px" }}>
                    {kycValidUerDesc?.area}
                  </Form.Text>
                )}
              </div>
            </div>

            <Form.Control
              required
              placeholder="請輸入地址"
              className="form-select mb-4 pl-3"
              onChange={userDataOnChange}
              value={userData?.address}
              id="address"
              autoComplete="off"
              isInvalid={kycValidUerStatus?.address === 3}
              isValid={kycValidUerStatus?.address === 2}
              disabled={
                kycValidUerStatus?.address === 2 ||
                kycValidUerStatus?.address === 1 ||
                isValidIng
              }
              style={{
                fontSize: "17px",
                borderColor: kycValidUerStatus?.address === 1 && "#d48806",
              }}
            />
            {kycValidUerStatus?.address === 1 && (
              <div style={{ marginTop: "-8px", color: "#d48806" }}>驗證中</div>
            )}
            {kycValidUerDesc?.address && (
              <Form.Text style={{ marginTop: "-8px" }}>
                {kycValidUerDesc?.address}
              </Form.Text>
            )}
          </Form.Group>

          <Form.Group className="mb-3" controlId="P2">
            <Form.Label>銀行戶名</Form.Label>
            <Form.Control
              required
              placeholder="輸入銀行戶名"
              className="form-select mb-4 pl-3"
              onChange={onChange}
              value={bankData.P2}
              autoComplete="off"
              isInvalid={bankStatus?.status?.P2 === 3}
              isValid={bankStatus?.status?.P2 === 2}
              disabled={
                bankStatus?.status?.P2 === 2 ||
                bankStatus?.status?.P2 === 1 ||
                isValidIng
              }
              style={{
                fontSize: "17px",
                borderColor: bankStatus?.status?.P2 === 1 && "#d48806",
              }}
            />
            {bankStatus?.status?.P2 === 1 && (
              <div style={{ marginTop: "-8px", color: "#d48806" }}>驗證中</div>
            )}

            {bankStatus?.des?.P2 && (
              <Form.Text style={{ marginTop: "-8px" }}>
                {bankStatus?.des?.P2}
              </Form.Text>
            )}
          </Form.Group>

          <Form.Group className="mb-3" controlId="P3">
            <Form.Label>銀行代號</Form.Label>
            <Form.Control
              required
              placeholder="輸入銀行代號"
              className="form-select mb-4 pl-3"
              onChange={onChange}
              value={bankData.P3}
              autoComplete="off"
              type="number"
              isInvalid={bankStatus?.status?.P3 === 3}
              isValid={bankStatus?.status?.P3 === 2}
              disabled={
                bankStatus?.status?.P3 === 2 ||
                bankStatus?.status?.P3 === 1 ||
                isValidIng
              }
              style={{
                fontSize: "17px",
                borderColor: bankStatus?.status?.P3 === 1 && "#d48806",
              }}
              onWheel={(event) => {
                event.currentTarget.blur();
              }}
            />
            {bankStatus?.status?.P3 === 1 && (
              <div style={{ marginTop: "-8px", color: "#d48806" }}>驗證中</div>
            )}
            {bankStatus?.des?.P3 && (
              <Form.Text style={{ marginTop: "-8px" }}>
                {bankStatus?.des?.P3}
              </Form.Text>
            )}
          </Form.Group>

          <Form.Group className="mb-3" controlId="P1">
            <Form.Label>銀行帳號</Form.Label>
            <Form.Control
              required
              placeholder="輸入銀行帳號"
              className="form-select mb-4 pl-3"
              onChange={onChange}
              value={bankData.P1}
              autoComplete="off"
              type="number"
              isInvalid={bankStatus?.status?.P1 === 3}
              isValid={bankStatus?.status?.P1 === 2}
              disabled={
                bankStatus?.status?.P1 === 2 ||
                bankStatus?.status?.P1 === 1 ||
                isValidIng
              }
              style={{
                fontSize: "17px",
                borderColor: bankStatus?.status?.P1 === 1 && "#d48806",
              }}
              onWheel={(event) => {
                event.currentTarget.blur();
              }}
              // onBlur={onBlurHandle}
              // isInvalid={blurShowError.account}
            />
            {bankStatus?.status?.P1 === 1 && (
              <div style={{ marginTop: "-8px", color: "#d48806" }}>驗證中</div>
            )}
            {bankStatus?.des?.P1 && (
              <Form.Text style={{ marginTop: "-8px" }}>
                {bankStatus?.des?.P1}
              </Form.Text>
            )}
          </Form.Group>

          <Form.Text muted>
            {validSuccess
              ? "已完成驗證"
              : "請務必確保您所輸入的資料，與您的身份文件一致"}
          </Form.Text>

          <button ref={formBtnRef} style={{ display: "none" }}></button>
        </Form>
      </Card>

      {!validSuccess && (
        <Card className={styles.container} style={{ marginTop: 0 }}>
          <div style={{ marginBottom: "1rem" }} className={styles.header}>
            實名驗證-上傳圖片
          </div>
          <Form.Text muted>*避免模糊</Form.Text>
          <Form.Text muted>*請勿使用經過編輯的圖片</Form.Text>
          <Form.Text muted>*檔案大小需在4MB內</Form.Text>
          {/* <Form.Text muted>*僅接受 .JPEG / .JPG / .PNG格式</Form.Text> */}

          <div style={{ marginTop: "1rem", fontSize: "18px" }}>
            <div>
              <div>上傳身分證 正 & 反面</div>
              <Space>
                {isValidIng ||
                kycValidUerStatus.IDPhotoFront === 1 ||
                kycValidUerStatus.IDPhotoFront === 2 ? (
                  <div>
                    <Image
                      width={140}
                      height={140}
                      src={userData.IDPhotoFront}
                      alt="id photo front"
                      style={{
                        border: "2px solid",
                        borderColor:
                          kycValidUerStatus.IDPhotoFront === 2
                            ? "#28a745"
                            : "#d48806",
                      }}
                    />
                    <span
                      style={{
                        fontSize: "10px",
                        color:
                          kycValidUerStatus.IDPhotoFront === 2
                            ? "#28a745"
                            : "#d48806",
                      }}
                    >
                      {kycValidUerStatus.IDPhotoFront === 2
                        ? "驗證成功"
                        : "驗證中"}
                    </span>
                  </div>
                ) : (
                  <>
                    <ImageUploader
                      style={{ "--cell-size": "140px" }}
                      value={idPhotoFront}
                      onChange={setIdPhotoFront}
                      upload={(file) => mockUpload(file, `IDPhotoFront`)}
                      maxCount={1}
                      showUpload={idPhotoFront.length < 1}
                      beforeUpload={beforeUpload}
                      showFailed={false}
                      imageFit="cover"
                    />
                    {(kycValidUerDesc?.IDPhotoFront ||
                      kycValidUerStatus?.IDPhotoFront === 3) && (
                      <Form.Text style={{ margin: "-8px, 0", fontSize: "8px" }}>
                        {`正面照片 - ${
                          kycValidUerDesc?.IDPhotoFront || "請重新上傳"
                        }`}
                      </Form.Text>
                    )}
                  </>
                )}

                {isValidIng ||
                kycValidUerStatus.IDPhotoBack === 1 ||
                kycValidUerStatus.IDPhotoBack === 2 ? (
                  <div>
                    <Image
                      width={140}
                      height={140}
                      src={userData.IDPhotoBack}
                      alt="id photo back"
                      style={{
                        border: "2px solid",
                        borderColor:
                          kycValidUerStatus.IDPhotoBack === 2
                            ? "#28a745"
                            : "#d48806",
                      }}
                    />
                    <span
                      style={{
                        fontSize: "10px",
                        color:
                          kycValidUerStatus.IDPhotoBack === 2
                            ? "#28a745"
                            : "#d48806",
                      }}
                    >
                      {kycValidUerStatus.IDPhotoBack === 2
                        ? "驗證成功"
                        : "驗證中"}
                    </span>
                  </div>
                ) : (
                  <>
                    <ImageUploader
                      style={{ "--cell-size": "140px" }}
                      value={idPhotoBack}
                      onChange={setIdPhotoBack}
                      upload={(file) => mockUpload(file, `IDPhotoBack`)}
                      maxCount={1}
                      showUpload={idPhotoBack.length < 1}
                      beforeUpload={beforeUpload}
                      showFailed={false}
                      imageFit="contain"
                    />
                    {(kycValidUerDesc?.IDPhotoBack ||
                      kycValidUerStatus?.IDPhotoBack === 3) && (
                      <Form.Text style={{ margin: "-8px, 0", fontSize: "8px" }}>
                        {`反面照片 - ${
                          kycValidUerDesc?.IDPhotoBack || "請重新上傳"
                        }`}
                      </Form.Text>
                    )}
                  </>
                )}
              </Space>
            </div>

            <div style={{ marginTop: "2rem" }}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span>上傳第二證件 正 & 反面</span>
                <span style={{ fontSize: "1rem", color: "#CFCFD1" }}>
                  健保卡/駕照/護照 擇一
                </span>
              </div>
              <Space>
                {isValidIng ||
                kycValidUerStatus.IDPhotoSecondFront === 1 ||
                kycValidUerStatus.IDPhotoSecondFront === 2 ? (
                  <div>
                    <Image
                      width={140}
                      height={140}
                      src={userData.IDPhotoSecondFront}
                      alt="id photo second front"
                      style={{
                        border: "2px solid",
                        borderColor:
                          kycValidUerStatus.IDPhotoSecondFront === 2
                            ? "#28a745"
                            : "#d48806",
                      }}
                    />
                    <span
                      style={{
                        fontSize: "10px",
                        color:
                          kycValidUerStatus.IDPhotoSecondFront === 2
                            ? "#28a745"
                            : "#d48806",
                      }}
                    >
                      {kycValidUerStatus.IDPhotoSecondFront === 2
                        ? "驗證成功"
                        : "驗證中"}
                    </span>
                  </div>
                ) : (
                  <>
                    <ImageUploader
                      style={{ "--cell-size": "140px" }}
                      value={IDPhotoSecondFront}
                      onChange={setIDPhotoSecondFront}
                      imageFit="contain"
                      upload={(file) => mockUpload(file, `IDPhotoSecondFront`)}
                      maxCount={1}
                      showFailed={false}
                      showUpload={IDPhotoSecondFront.length < 1}
                      beforeUpload={beforeUpload}
                    />
                    {(kycValidUerDesc?.IDPhotoSecondFront ||
                      kycValidUerStatus?.IDPhotoSecondFront === 3) && (
                      <Form.Text style={{ margin: "-8px, 0", fontSize: "8px" }}>
                        {`正面照片 - ${
                          kycValidUerDesc?.IDPhotoSecondFront || "請重新上傳"
                        }`}
                      </Form.Text>
                    )}
                  </>
                )}

                {isValidIng ||
                kycValidUerStatus.IDPhotoSecondBack === 1 ||
                kycValidUerStatus.IDPhotoSecondBack === 2 ? (
                  <div>
                    <Image
                      width={140}
                      height={140}
                      src={userData.IDPhotoSecondBack}
                      alt="id photo second back"
                      style={{
                        border: "2px solid",
                        borderColor:
                          kycValidUerStatus.IDPhotoSecondBack === 2
                            ? "#28a745"
                            : "#d48806",
                      }}
                    />
                    <span
                      style={{
                        fontSize: "10px",
                        color:
                          kycValidUerStatus.IDPhotoSecondBack === 2
                            ? "#28a745"
                            : "#d48806",
                      }}
                    >
                      {kycValidUerStatus.IDPhotoSecondBack === 2
                        ? "驗證成功"
                        : "驗證中"}
                    </span>
                  </div>
                ) : (
                  <>
                    <ImageUploader
                      style={{ "--cell-size": "140px" }}
                      value={IDPhotoSecondBack}
                      onChange={setIDPhotoSecondBack}
                      imageFit="contain"
                      upload={(file) => mockUpload(file, `IDPhotoSecondBack`)}
                      maxCount={1}
                      showFailed={false}
                      showUpload={IDPhotoSecondBack.length < 1}
                      beforeUpload={beforeUpload}
                    />
                    {(kycValidUerDesc?.IDPhotoSecondBack ||
                      kycValidUerStatus?.IDPhotoSecondBack === 3) && (
                      <Form.Text style={{ margin: "-8px, 0", fontSize: "8px" }}>
                        {`反面照片 - ${
                          kycValidUerDesc?.IDPhotoSecondBack || "請重新上傳"
                        }`}
                      </Form.Text>
                    )}
                  </>
                )}
              </Space>
            </div>

            <div style={{ marginTop: "2rem" }}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span>上傳手持證件自拍照</span>
                <span style={{ fontSize: "1rem", color: "#CFCFD1" }}>
                  身分證件請勿遮住臉部
                </span>
                <span style={{ fontSize: "1rem", color: "#CFCFD1" }}>
                  手指請勿遮住證件文字
                </span>
              </div>

              {isValidIng ||
              kycValidUerStatus.selfPhoto === 1 ||
              kycValidUerStatus.selfPhoto === 2 ? (
                <div>
                  <Image
                    width={140}
                    height={140}
                    src={userData.selfPhoto}
                    alt="self"
                    style={{
                      border: "2px solid",
                      borderColor:
                        kycValidUerStatus.selfPhoto === 2
                          ? "#28a745"
                          : "#d48806",
                    }}
                  />
                  <span
                    style={{
                      fontSize: "10px",
                      color:
                        kycValidUerStatus.selfPhoto === 2
                          ? "#28a745"
                          : "#d48806",
                    }}
                  >
                    {kycValidUerStatus.selfPhoto === 2 ? "驗證成功" : "驗證中"}
                  </span>
                </div>
              ) : (
                <>
                  <ImageUploader
                    style={{ "--cell-size": "140px" }}
                    value={selfList}
                    onChange={setSelfList}
                    upload={(file) => mockUpload(file, "selfPhoto")}
                    maxCount={1}
                    showFailed={false}
                    showUpload={selfList.length < 1}
                    beforeUpload={beforeUpload}
                    imageFit="contain"
                  />
                  {(kycValidUerDesc?.selfPhoto ||
                    kycValidUerStatus?.selfPhoto === 3) && (
                    <Form.Text style={{ margin: "-8px, 0", fontSize: "8px" }}>
                      {`自拍照 - ${kycValidUerDesc?.selfPhoto || "請重新上傳"}`}
                    </Form.Text>
                  )}
                </>
              )}
            </div>

            <div style={{ marginTop: "2rem" }}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span>上傳存摺正面</span>
              </div>
              {isValidIng ||
              bankStatus?.status?.Img === 1 ||
              bankStatus?.status?.Img === 2 ? (
                <div>
                  <Image
                    width={140}
                    height={140}
                    src={bankData?.Img1}
                    alt="account"
                    style={{
                      border: "2px solid",
                      borderColor:
                        bankStatus.status?.Img === 2 ? "#28a745" : "#d48806",
                    }}
                  />
                  <span
                    style={{
                      fontSize: "10px",
                      color:
                        bankStatus.status?.Img === 2 ? "#28a745" : "#d48806",
                    }}
                  >
                    {bankStatus.status?.Img === 2 ? "驗證成功" : "驗證中"}
                  </span>
                </div>
              ) : (
                <>
                  <ImageUploader
                    style={{ "--cell-size": "140px" }}
                    value={accountList}
                    onChange={setAccountList}
                    upload={(file) => mockUpload(file, "Img1")}
                    maxCount={1}
                    showFailed={false}
                    showUpload={accountList.length < 1}
                    beforeUpload={beforeUpload}
                    imageFit="contain"
                  />

                  {(bankStatus?.des?.Img || bankStatus?.status?.Img === 3) && (
                    <Form.Text style={{ margin: "-8px, 0", fontSize: "8px" }}>
                      {`存摺 - ${bankStatus?.des?.Img || "請重新上傳"}`}
                    </Form.Text>
                  )}
                </>
              )}
            </div>

            <div style={{ marginTop: "2rem" }}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span>上傳本人簽名</span>
              </div>
              {isValidIng ||
              kycValidUerStatus.sign === 1 ||
              kycValidUerStatus.sign === 2 ? (
                <div>
                  <Image
                    width={140}
                    height={140}
                    src={userData?.sign}
                    alt="sign"
                    style={{
                      border: "2px solid",
                      borderColor:
                        kycValidUerStatus.sign === 2 ? "#28a745" : "#d48806",
                    }}
                  />
                  <span
                    style={{
                      fontSize: "10px",
                      color:
                        kycValidUerStatus.sign === 2 ? "#28a745" : "#d48806",
                    }}
                  >
                    {kycValidUerStatus.sign === 2 ? "驗證成功" : "驗證中"}
                  </span>
                </div>
              ) : (
                <>
                  <ImageUploader
                    style={{ "--cell-size": "140px" }}
                    value={signList}
                    onChange={setSignList}
                    upload={(file) => mockUpload(file, "sign")}
                    maxCount={1}
                    showFailed={false}
                    showUpload={signList?.length < 1}
                    beforeUpload={beforeUpload}
                    imageFit="contain"
                  />
                  {(kycValidUerDesc?.sign || kycValidUerStatus?.sign === 3) && (
                    <Form.Text style={{ margin: "-8px 0", fontSize: "8px" }}>
                      {`簽名 - ${kycValidUerDesc?.sign || "請重新上傳"}`}
                    </Form.Text>
                  )}
                </>
              )}
            </div>
          </div>

          {!validSuccess && (
            <Button
              variant="primary"
              type="button"
              disabled={userKycLoading || isValidIng}
              style={{
                height: "5rem",
                marginTop: "3rem",
                fontSize: "2rem",
                backgroundColor: isValidIng && "grey",
              }}
              onClick={() => {
                formBtnRef.current.click();
              }}
            >
              {userKycLoading && (
                <Spinner
                  as="span"
                  animation="border"
                  size="lg"
                  role="status"
                  aria-hidden="true"
                />
              )}
              {isValidIng ? "驗證中" : "填寫完成"}
            </Button>
          )}

          {validSuccess && (
            <div
              style={{
                height: "5rem",
                marginTop: "3rem",
                fontSize: "2rem",
                display: "flex",
                justifyContent: "center",
              }}
            >
              已經完成驗證
            </div>
          )}
        </Card>
      )}
    </>
  );
};

export default KycValidForm;
