export const urlParamsKey = {
  session_id: "session_id",
  user: "user",
  location: "location",
  order: "order",
};

export const getParamsFromUrl = (params) => {
  try {
    const str = `?${window.location.hash.split("?")[1]}`;
    const searchParams = new URLSearchParams(str);
    return searchParams.get(params) || "";
  } catch (error) {
    console.log(error);
  }
};
