// eslint-disable-next-line
export default {
  // K100U Rule
  agreement_title: "88UU法幣區交易規則",
  agreement_rule: "法幣區交易基本原則：",
  agreement_rule_list: [
    "本規則自發布之日起正式實施，並將不時予以修改或完善，修改或完善後的新規則自公佈之日起即生效。",
    "如您不同意本規則及修改或完善後的規則內容，請立即停止使用平台服務",
    "如您繼續使用平台服務的，視為您完全理解並接受本規則及不時修改或完善後的規則內容，並自願受其約束",
  ],

  // Buy Rule
  agreement_buy_rule: "買方應遵循的原則：",
  agreement_buy_rule_sub: "創建訂單後，買方應當：",
  agreement_buy_rule_list: [
    "在訂單規定的付款時間內完成支付，再點擊「我已付款」",
    "使用本人實名付款方式、按訂單金額及訂單指定的收款帳戶以實時到帳方式完成訂單支付",
    "選擇非實時到帳方式支付訂單的，必須事先獲得賣方同意",
    "支付訂單不得備註「數字貨幣、USDT」等信息",
    // "支付訂單不得備註「數字貨幣、USDT」等信息",
    "創建訂單後，賣家收到的金額總數應和訂單詳情介面的金額一致。若在支付過程中產生了額外的費用，需由買家承擔",
  ],

  // Sell Rule
  agreement_sell_rule: "賣方應遵循的原則：",
  agreement_sell_rule_sub: "生成訂單後，賣方應當：",
  agreement_sell_rule_list: [
    "提供經平台認證的收款帳戶",
    "收到買方全額付款後，在訂單規定時間內完成放行",
    // "收到買方全額付款後，在訂單規定時間內完成放行",
    "非經買方同意，不得對已創建的訂單價格提出異議",
  ],

  // User Rule
  user_rule: "用戶應遵守反洗錢原則：",
  user_rule_list: [
    "用戶應遵守平台的《88U 條款和條件》並嚴格照此履行相關義務",
    "用戶應遵守平台的《88U 條款和條件》並嚴格照此履行相關義務",
    "用戶在觸發平台的反洗錢風控預警時，應積極配合平台的進一步反洗錢核實工作，並積極協助提供相關的風控舉證材料（具體措施以屆時平台風控審核要求為準）",
  ],

  // Table #1 Th
  table_1_th: ["#", "異常處理", "處理措施"],

  // Table #1 Td
  table_1_td_1: [
    "1",
    "訂單創建後，買方主動取消訂單，或者因付款超時而係統自動取消訂單。",
    "當日達到4次，系統將限制買方買入功能；連續2日觸發，系統將進一步限制買方賬戶部分功能。",
  ],
  table_1_td_2: [
    "2",
    "未付款的情況下，點擊“我已付款",
    // "賣方有權要求取消此筆訂單，買方當日被投訴達到2次，系統將限制買方賬戶部分功能；此類投訴單筆金額超過8萬人民幣的，系統將直接限制買方賬戶48小時。",
    "賣方有權要求取消此筆訂單，買方當日被投訴達到2次，系統將限制買方賬戶功能。",
  ],
  table_1_td_3: [
    "3",
    "賣方無違規，買方未付款的情況下，無故點擊“申訴”。",
    "賣方有權要求取消此筆訂單，買方當日被投訴達到2次，系統將限制買方賬戶部分功能；",
  ],
  table_1_td_4: [
    "4",
    "未按照賣方訂單詳情頁面顯示的收款賬戶進行付款。",
    "賣家有權要求取消訂單，數字資產歸還給賣方，買方自行承擔轉錯款的風險。",
  ],
  table_1_td_5: [
    "5",
    "未付款的情況下，優先點擊“我已付款”，再進行支付。",
    "賣方有權選擇繼續成交或者退款後取消訂單。 1）如賣方選擇成交，賣方用戶需要在收到全部付款的10分鐘內完成放行；2）如賣方選擇取消訂單，賣方應在訂單申訴後2小時內完成退款，數字資產歸還給賣方。",
  ],
  table_1_td_6: [
    "6",
    "未使用與平台賬戶實名一致的支付賬戶付款。",
    "賣方有權直接拒絕成交，賣方可在收到付款後的48小時內完成退款處理，數字資產歸還給賣方。使用企業賬戶付款（不論法人與認證本人實名是否一致），依然屬於非實名支付。買方因此類情況被投訴達到2次，系統將限制買方賬戶的部分功能。",
  ],
  table_1_td_7: [
    "7",
    "在訂單規定的付款時間內付款。 （1）買方已完成所有付款，訂單被買方主動取消或被系統超時取消；（2）買方完成部分付款，但訂單被買方主動取消或被系統超時取消。",
    "訂單被買方主動取消以及訂單被系統超時取消的，賣方有權選擇繼續成交或者退款不成交。 1、如賣方選擇成交，—賣方是普通用戶，訂單由平台直接重開，賣方需在重開後，10分鐘內完成資產放行。 —賣方是廣告方用戶，訂單金額≤8萬，請使用“重開訂單並放行”完成資產放行操作；訂單金額＞8萬，請在訂單重開10分鐘內完成資產放行。 2、如賣方選擇取消訂單，則賣方需在收到平台或者買方用戶反饋後60分鐘內完成退款，數字資產歸還給賣方；",
  ],
  table_1_td_8: [
    "8",
    "如需要多筆付款，未完成所有轉款的情況下，優先點擊“我已付款”，再繼續完成付款。",
    "1）提前與賣方溝通，賣方允許其行為的，賣方應繼續完成訂單；2）未徵得賣方同意，賣方有權選擇繼續成交或者退款後取消訂單。 —如賣方選擇繼續成交，賣方應在收到所有付款的10分鐘內完成放行。 —如賣方選擇取消訂單，則賣方需在收到付款後30分鐘內完成退款，數字資產歸還給賣方。",
  ],
  table_1_td_9: [
    "9",
    "付款金額與訂單金額不符。",
    "1）付款金額大於訂單金額的，訂單成交，賣家應於收到款項後30分鐘內退還多餘部分款項。 2）付款金額小於訂單金額的，賣方有權選擇繼續成交或者退款後要求取消訂單。 —如賣方選擇繼續成交，有權按實際訂單金額進行成交，並完成此訂單。 —如賣方選擇取消訂單，則賣方需在收到付款後30分鐘內完成退款，數字資產歸還給賣方。",
  ],
  table_1_td_10: [
    "10",
    "買方點擊“我已付款”後，賣方1小時內未收到付款。",
    "1）賣方有權選擇繼續成交或退款後取消訂單。 —如賣方選擇成交，賣方需要在收到付款的10分鐘內完成放行。 —如賣方選擇取消訂單，則賣方需在收到付款後30分鐘內完成退款，數字資產歸還給賣方。 2）賣方是廣告發布方，申訴後10分鐘內無法聯繫上買方，經核實賣方未收到款，在廣告方保證資產範圍內申訴專員會優先取消訂單。",
  ],
  table_1_td_11: [
    "11",
    "付款到賬後，賣方收款賬戶無法核實到賬實名信息。",
    "1）經核實買方付款賬戶與平台實名賬戶一致，且在訂單規定時間內完成了付款，此訂單正常成交。 2）經核實買方付款賬戶與平台實名賬戶不一致，按上述第6條措施處理。",
  ],
  table_1_td_12: [
    "12",
    "如買方付款後賣方收款賬戶異常。",
    "1）買方無法提供賬戶正常證明，賣方應在收款賬戶轉至正常後，24小時內完成退款，平台取消訂單。 2）買方能提供賬戶正常證明，在核實買方賬戶無異常後，訂單成交，數字資產應交付買方。如：賣方為加V用戶，在保證資產範圍內，數字資產歸還給賣方，賣方應在收款賬戶轉至正常後，24小時內完成退款。",
  ],
  table_1_td_13: [
    "13",
    "付款時備註：類似”數字貨幣、BTC、比特幣、火幣“等字樣的。",
    "賣方有權選擇繼續成交或退款不成交。 1）如賣方選擇成交，賣方應在收到付款的10分鐘內完成放行。 2）如賣方選擇退款不成交，此訂單取消，數字資產歸還給賣方，賣方在收到付款後60分鐘內完成退款；如賣方收款賬戶因此被凍結，按上述第12條措施處理。",
  ],
  table_1_td_14: [
    "14",
    "買方用戶被風控系統判斷惡意搶拍低價單",
    " 1）買方未付款，平台將取消訂單，資產歸還給賣方。 2）買方已付款，請賣方在申訴處理的10分鐘內完成退款處理，平台將協助取消訂單，資產歸還給賣方。買方此類行為達到兩次，風控系統將會限制買方賬戶部分功能。",
  ],
  table_1_ps: [
    "注：本規則涉及賣方退款或放行，而賣家未按時完成操作的，系統將限制賣方賬戶部分功能。",
    "注：OTC交易使用三方電話系統過程中如您主動挑起語言衝突，有辱罵、嘲諷或其他語言過激行為等情況，系統將限制您賬戶部分功能。",
  ],

  // Table #2 Th
  table_2_th: ["#", "異常處理", "處理措施"],

  // Table #2 Td
  table_2_td_1: [
    "1",
    "賣方為訂單詳情頁面展示的收款賬戶、以及訂單聊天窗口發送的收款賬戶負全部責任；",
    "賣方提供的收款賬戶非本人所有，或受他人引導綁定他人收款賬戶，或發送他人賬戶到聊天窗。買方完成付款後，資產判定將屬於買方。",
  ],
  table_2_td_2: [
    "2",
    "訂單生成後，對訂單價格有異議的。",
    "除經買方同意協商訂單價格外，訂單正常成交，賣方應在訂單規定時間內放行。",
  ],
  table_2_td_3: [
    "3",
    "賣方所提供的收款賬戶出現異常。 ",
    "買方有權選擇繼續成交或取消訂單。 —如買方選擇成交，確認買方已在訂單規定的時間內全額付款的，賣家應在收到付款後，在訂單規定的時間完成放行。 —如買方選擇取消訂單的，賣方完成退款後數字資產歸還給賣方。",
  ],
  table_2_td_4: [
    "4",
    "因賣方未及時提供有效的收款方式，買方無法完成付款，導致訂單取消。",
    "因賣方不提供收款方式，此訂單無法成交，平台在處理申訴時，會直接取消訂單，並對賣方用戶做處罰，平台取消訂單不計買方當天取消次數；",
  ],
  table_2_td_5: [
    "5",
    "賣方（非廣告方），未綁定與本人實名一致的有效收款信息或向買方提供與平台實名不一致的收款方式，如：通過支付寶或者微信收款時，賬號以及二維碼收款人姓名與訂單詳情頁展示的收款人姓名不匹配。",
    "1）買方有權取消訂單並向平台投訴。 2）賣方因此被投訴達到3次，系統將會永久限制賣方賬戶。買方選擇繼續成交，因此被投訴達到2次，系統將限制買方賬戶部分功能。",
  ],
  table_2_td_6: [
    "6",
    "賣方收取買方非平台實名一致的賬戶付款，未拒絕成交的。",
    "1）由此引發的風險，由賣方自行承擔。 2）因此而被投訴的，系統將限制賣方賬戶部分功能。",
  ],
  table_2_td_7: [
    "7",
    "買方全額付款後標記“我已付款”，賣方收到買方全額付款後，未在20分鐘內完成放行。",
    "買方有權選擇繼續成交或取消訂單後要求退款。 —如買方選擇成交，申訴專員將判定資產屬於買方。 —如買方要求退款後取消訂單，賣方應在30分鐘內完成退款。如：買方在標記付款20分鐘以內點擊申訴，導致賣方無法放行，且賣方在聊天窗無法與買方取得聯繫，此訂單將正常成交。",
  ],
  table_2_ps:
    "注：OTC交易使用三方電話系統過程中如您主動挑起語言衝突，有辱罵、嘲諷或其他語言過激行為等情況，系統將限制您賬戶部分功能。",

  // Table #3 Th
  table_3_th: ["#", "觸發風控異常", "處理措施"],

  // Table #2 Td
  table_3_td_1: [
    "1",
    "當用戶被風控系統檢測到異常，用戶明確拒絕或不積極配合完成平颱風控要求的進一步反洗錢核查工作。",
    "本平台將有權選擇直接認定為此賬戶異常，並對此採取包括但不限於上調用戶風險等級、直接凍結此類用戶的賬戶及關聯賬戶直至用戶配合提供的風控舉證材料已通過平台的風控審核。",
  ],
  table_3_td_2: [
    "2",
    "當用戶被核實從 88U OTC 買幣去其他平台場外群售出以獲利等其他高危行為。",
    "本平台將視情況有權選擇採取包括但不僅限於延遲提幣、限制交易、凍結賬戶等處置措施； 注：此類行為一經發現，如有進行中的訂單，廣告方有權選擇不成交。",
  ],
  table_3_td_3: [
    "3",
    "賬戶非本人使用或為他人代買賣幣：當用戶被平颱風控核實出賬戶存在出售、出借、租賃自身賬戶為他人使用的情況，或存在為他人代買賣幣的行為。風險提醒：請注意自身信息安全，避免被他人利用進行犯罪行為。",
    "本平台將有權選擇包括但不限於暫時或永久限制OTC交易、延遲提幣、限制提幣或直接凍結此類用戶的賬戶及關聯賬戶等某項或多項措施。注：此類行為一經發現，如有進行中的訂單，廣告方有權選擇不成交。",
  ],
  table_3_td_4: [
    "4",
    "利用平台其他用戶測卡：用戶在訂單未發生的情況下向平台其他用戶小額打款，且無法合理解釋該行為時。",
    "本平台將有權選擇暫時或永久限制OTC交易、限制提幣或凍結賬戶等某項或多項措施。",
  ],
  table_3_td_5: [
    "5",
    "接收或引入來源不合法的數字資產或資金：當用戶明知或據合理常識判斷可推知在其他平台/微信群收到的幣為黑幣或資金為黑錢時，但仍無視風險並直接將該黑幣/黑錢流入到本平台其它用戶的賬戶。",
    "本平台將直接永久限制此類用戶的賬戶及關聯賬戶全部功能。",
  ],
  table_3_td_6: [
    "6",
    "被認定洗錢的用戶：當用戶被風控系統檢測或風控工作人員核實，用戶直接參與或協助洗黑錢等犯罪行為。",
    "本平台將直接永久限制此類用戶的賬戶及關聯賬戶全部功能。",
  ],
  table_3_td_7: [
    "7",
    "被司法凍結：當用戶賬戶被司法機構要求凍結時。",
    "本平台將嚴格按照司法機構出示的司法函件對用戶賬戶進行凍結。當凍結期滿，司法機構需出示續凍函方可繼續凍結。凍結期間，用戶向本平台申請解凍無效，請聯繫凍結機構協調。",
  ],
  table_3_td_8: [
    "8",
    "被司法調證：用戶被司法機構要求調取本平台賬戶資料，包括但不限於：司法調證、諮詢等形式。",
    "本平台將視情況限制此類用戶的賬戶及關聯賬戶的部分功能。",
  ],

  // ========
  //   Utils
  // ========
  // Form Label
  understand_agreement: "我了解協議內容",
  e_wallet: "",
  choose_e_wallet: "",

  // No Data
  no_data: "目前還沒有資料",
  no_data_2: "沒有數據",

  // No Order
  no_order: "訂單不存在",
  no_order_buy: "買",
  no_order_sell: "賣",

  // Alert text
  no_token: "沒有Token",
  cancel_fail: "訂單取消失敗",

  // Button Text
  btn_continue: "繼續",
  btn_confirm: "確定",
  btn_return: "返回",
  btn_pair_start: "開始配對",
  btn_pairing: "配對中",
  btn_loading: "處理中",
  btn_already_pay: "已完成付款",
  btn_cancel_order: "取消訂單",
  btn_over_time: "已逾时",
  btn_copy: "複製成功",
  btn_copy_fail: "複製失败，請手動複製",
  btn_back_home: "返回主頁",
  btn_transaction_detail: "詳細交易紀錄",
  btn_confirm_cancel: "確定取消訂單",
  btn_fetch_all: "提取所有",
  btn_next: "下一步",
  btn_buyer_already_pay: "買家已付款，確認收款",
  btn_preparing: "對方準備中",
  btn_close: "關閉",
  btn_detail: "詳細",
  btn_instant: "即時交易",
  btn_login: "登入",
  btn_forget_password: "忘記密碼",
  btn_send_valid_code: "發送驗證碼",
  btn_valid_code_already_send: "驗證碼已經發送",
  btn_back_to_login: "回登入页面",

  btn_bank: "銀行資訊",
  btn_aliPay: "支付寶",

  // WelCome Text
  welcome_text: "歡迎登入",

  // ExRate Text
  exRate: "匯率",
  payment_contact: "付款窗口",
  limit: "限額",
  payment_contact_time: "15分鐘",
  limit_usdt: 'USDT 30.00 - ',

  // Dialog
  please_wait: "請稍等，現正整合交易者資料",
  buy_order: "購買訂單",

  // ========
  //   User
  // ========
  // Navigation
  nav_transaction: "交易",
  nav_history: "紀錄",
  nav_wallet: "錢包",
  nav_logout: "登出",
  nav_logout_alert: "確定要登出嗎？",

  // Balance
  balance_avb: "可提",
  balance_real: "結餘",

  // Over View
  overView_transaction: "買賣",
  overView_transfer: "轉帳",
  overView_wallet: "我的錢包",
  overView_history: "交易紀錄",

  // Slogan
  slogan_title: "最值得信賴的加密貨幣交易平台",
  slogan_sub_text_1: "只要簡單幾個步驟即可創建您自己的加密錢包",
  slogan_sub_text_2: "您可以安全地購買，出售，和交易您的加密貨幣!!",

  // Transaction Navigation
  transaction_nav_buy: "購買",
  transaction_nav_sell: "出售",
  transaction_nav_transfer: "轉帳",

  // =========
  //  Balance
  // =========
  // Error Text
  avb_insufficient: "可提不足,請確定金額扣除手續費後是否足夠",
  avb_over_limit: "超過最大可提",

  // =======
  //  Buy
  // =======
  buy_usdt: "購買USDT",

  // Buy Form
  // Error Text
  no_account_name: "必須填寫戶名",
  no_bank_account: "必須填寫銀行帳號",
  no_bank_code: "必須填寫銀行代碼",
  invalid_number: "請輸入有效的購買數量",

  // Form Text
  please_enter_quantity: "請輸入購買數量",
  account_name_placeholder: "請輸入戶名",
  account_placeholder: "請輸入銀行帳號",
  bank_code_placeholder: "請輸入銀行代碼",
  account_name_prompt: "*ATM轉帳時請註記持有人的真實姓名，並顯示於雙方明細，資料不符時將不受理交易服務，並退還收款金額，相關手續費會於退款時一併扣除，若有疑問請洽客服，謝謝。",
  buy_total: "總價",
  buy_quantity: "數量",

  // Buy Info Header
  transfer_data: "轉帳資料",
  limit_time: "剩餘支付時間",

  // Buy Detail
  amount: "付款金額",
  payee: "戶名",
  payee_account: "銀行帳號",
  bank: "銀行代碼",
  city: "所在省市",

  // =======
  //  Sell
  // =======
  // Sell Pairing
  pair_title: "請稍等，现正整合交易者资料",
  pair_text: "出售訂單",

  // Sell ExRate
  sell_usdt: "出售USDT",

  // Sell Error Text
  sell_error_invalid_number: "請輸入有效數量",
  sell_error_enter_payee: "請輸入戶名",
  sell_error_enter_bank: "請輸入銀行代碼",
  sell_error_enter_payee_account: "請輸入銀行帳號",
  sell_error_enter_city: "請輸入所在省市",

  // Sell Form
  sell_enter_qua: "請輸入出售數量",

  // Sell Bank Form
  sell_payee: "戶名",
  sell_payee_account: "銀行帳號",
  sell_bank: "銀行代碼",
  sell_city: "所在省市",

  // Sell Header
  sell_info_transfer_data: "轉帳資料",
  sell_info_limit_time: "剩餘支付時間",

  // Sell Detail
  sell_over_time: "交易已逾時",
  sell_detail_amount: "收款金額",
  sell_detail_payee: "戶名",
  sell_detail_payee_account: "銀行帳號",
  sell_detail_payee_bank: "銀行代碼",
  sell_detail_payee_city: "所在省市",

  // ==========
  //  Transfer
  // ==========
  // Error Text
  transfer_address_error: "錢包地址錯誤",
  transfer_error_invalid_number: "請輸入有效數量",

  // Transfer Header
  select_protocol: "請選擇協議種類",
  protocol_trc20: "TRC20",
  protocol_erc20: "ERC20",
  transfer_charge: "手續費",
  transfer_title: "轉帳USDT",
  transfer_address: "轉帳地址",
  open_camera: "開啟相機掃描QR Code?",

  // Transfer Info
  transfer_info_success: "交易成功",
  transfer_info_address: "轉帳地址",
  transfer_info_hash: "交易回執",
  transfer_info_loading: "轉帳中",
  transfer_info_fail: "轉帳失敗",

  // Transfer Loading
  transfer_onLoading: "執行中，請稍等",
  transfer_text: "轉賬",

  // Transfer Form
  transfer_enter_number: "請輸入數量",
  transfer_enter_address: "請輸入收款地址",

  // Complete State
  wait_confirm: "已提交，等待確認中",
  transaction_hash: "交易回執",
  complete_text: "購買成功後，數字貨幣預計15~30分鐘內到達你的錢包地址",
  transaction_done: "交易完成",
  transaction_cancel: "交易取消",
  transaction_over_time: "交易超時",

  // Cancel Order Component
  cancel_component_title: "確定取消訂單嗎？",
  order_number: "訂單號",

  // Form Footer
  form_footer_text_1: "本平台目前只提供USDT交易，其他數字貨幣交易將不予受理",
  form_footer_text_2: "本平台錢包地址充值或轉出都是經由USDT(ERC/TRC區塊鏈系統確認)，其它鏈例如:幣安智能鏈(BNB CHAIN或其他)，本平台錢包將不會收到轉入金額，並不會上帳且無法退還。",
  form_footer_text_3: "本平台錢包地址可以重覆充值或轉出；如因系統更新，我們會通過網站或申訴窗口通知。",
  form_footer_text_4: "請勿向錢包地址充值任何非 USDT 資產，否則資產將不可找回。",
  form_footer_text_5: "最小充值金額：30USDT，小於最小金額的充值將不會上帳且無法退回。",
  form_footer_text_6: "請務必確認電腦及瀏覽器安全，防止資訊被篡改或洩露。",
  form_footer_text_7: "ATM轉帳請備註持有人的真實姓名，並顯示於雙方明細。",
  form_footer_text_8: "轉帳資料不符時，將不受理交易服務，並退還收款金額，如因跨行造成的手續費會於退款時一併扣除。",
  form_footer_text_9: "雙方交易時，若匯款金額有溢出，收款方有義務將溢出金額歸還給匯款方，否則將依法律途徑追討。",
  form_footer_text_10: "如有其他問題或要求提出申訴，可透過網頁上的客服對話窗聯絡我們。",

  // ==========
  //  History
  // ==========
  // History nav
  history_all: "所有紀錄",
  history_wait: "待處理",
  history_expires: "超時訂單",
  history_date: "日期",
  history_transaction_deal: "交易額（USDT)",
  history_transaction_real: "結餘（USDT)",
  history_transaction_status: "狀態",
  history_transaction_complete: "完成",
  history_previousLabel: "上一頁",
  history_nextLabel: "下一頁",

  // History All Detail
  history_buy: "買入",
  history_sell: "賣出",
  history_transfer_in: "轉入",
  history_transfer_out: "轉出",
  history_transaction_hash: "交易回執",
  history_transaction_charge: "手續費",
  history_transaction_price: "兌換價",
  history_RMB: "RMB",
  history_account: "銀行帳號",
  history_payee: "戶名",
  history_bank: "銀行代碼",
  history_branch: "分行",
  history_payer: "付款人",
  history_payer_account: "付款銀行帳號",
  history_payer_bank_code: "付款銀行代碼",
  history_currency: "TWD",

  // History Wait
  history_wait_pay: "等待付款",
  history_account_receivable: "收款確認中",
  history_onGoing: "執行中",

  // ==========
  //  Wallet
  // ==========
  wallet_address: "充值地址",

  // ==========
  //  Chat
  // ==========
  chat_help: "幫助",
  chat_sound_open: "提示音已開啟",
  chat_sound_close: "提示音已關閉",
  chat_order_number: "訂單號",
  chat_customer_server: "客服",
  chat_send_message: "對話",

  // ==========
  //  Instant
  // ==========
  instant_transaction: "即時買賣",
  instant_sound_open: "提示音已開啟",
  instant_sound_close: "提示音已關閉",
  instant_over_time: "交易已逾时",
  instant_over_time_short: "已逾时",
  instant_auto_pick: "自動接單",
  instant_auto_pick_close: "手動接單",

  // Instant Nav
  instant_nav_all: "即時買賣",
  instant_nav_onGoing: "進行中",

  // Instant All Item && Instant Count
  instant_exRate: "匯率",
  instant_price: "總價",
  instant_qua: "數量",
  instant_acc_time: "累計時間",
  instant_limit_time: "剩餘支付時間",
  instant_buy: "買",
  instant_pay: "付",
  instant_sell: "賣",
  instant_get: "收",
  instant_no_transaction: "目前没有交易",

  // Instant Buy Detail
  instant_payee_data: "收款方資料",
  instant_pay_time: "剩餘支付時間",
  instant_payee_name: "戶名",
  instant_payee_account: "銀行帳號",
  instant_bank: "銀行代碼",
  instant_city: "所在省市",
  instant_payer_data: "付款方資料",
  instant_payer_name: "付款方姓名",
  instant_payer_account: "付款帳號",
  instant_payer_bankCode: "付款銀行代碼",

  // ==========
  //  Auth
  // ==========
  // Index
  auth_login: "登入帳號",
  auth_register: "註冊帳號",
  auth_nav_login: "登入",
  auth_nav_register: "註冊",
  register_success: "注册成功",

  // Alert Text
  alert_text_1: "*點擊按鈕後發送一次性驗證碼",

  // Error Text
  no_countryCode: "請選擇區碼",
  invalid_phoneNumber: "請輸入有效的電話號碼",
  invalid_password: "密碼只能是英文及數字，且至少六位数",
  confirm_password_fail: "兩次密碼不一致",
  no_account: "此帳號未註冊",
  captcha_error: "驗證碼錯誤",

  // Form Input
  countryCode: "區號",
  china: "中國＋86",
  taiwan: "台灣＋886",
  singapore: "新加玻＋65",
  malaysia: "馬來西亞＋60",
  hk: "香港＋852",

  // Placeholder
  phoneNumber: "手機號碼前面不需要加0",
  password: "密碼",
  enter_valid: "輸入驗證碼",
  enter_password: "設置密碼",
  enter_new_password: "輸入新密碼",
  enter_confirm_password: "確認密碼",
  captcha_check: "驗證碼區分大小寫",

  // Label
  read_and_agree: "我已閱讀並同意",
  user_agreement: "《用戶協議》",
  click_and_send_valid_code: "點擊按鈕後發送驗證碼",
  one_time_code: "一次性驗證碼",

  // 中國區提示
  china_area_alert_1: "請注意！",
  china_area_alert_2: "因你身處地區受當地電訊條例限制，可能會較慢受到系統發出的簡訊，請耐心等候或重新申請一次。如仍未能成功或其他問題，請聯絡我們客戶服務微信號，即有專人協助。微信帳號： 238bien",

  // ===========
  //  Http Text
  // ============
  login_again: "請重新登入",
  set_password_success: "密碼設定成功",
  password_changed: "密碼已經更換",
  http_error_code_1: "系統錯誤",
  http_error_code_10: "帳號或密碼錯誤",
  http_error_code_11: "此帳號已經註冊過",
  http_error_code_12: "此帳號無法註冊",
  http_error_code_13: "json格式錯誤",
  http_error_code_14: "json格式錯誤",
  http_error_code_15: "無效的token",
  http_error_code_16: "錯誤的操作",
  http_error_code_17: "帳號未註冊",
  http_error_code_20: "數據格式錯誤",
  http_error_code_21: "請勿連續發送請求",
  http_error_code_22: "驗證碼錯誤",
  http_error_code_30: "無效的錢包地址",
  http_error_code_31: "不能轉帳給自己",
  http_error_code_32: "可提不足,請確定金額扣除手續費後是否足夠",
  http_error_code_41: '交易額度不足/每次',
  http_error_code_42: '交易額度不足/30天',
  http_error_code_91: "session過期,請重新登入",
  http_error_other: "發生錯誤，請重新登入並重新嘗試",

  //** Agent Acc */
  // EditBankInfoForm.js
  EditBankInfoForm_account_info: "帳戶資訊",
  EditBankInfoForm_edit_account_info: "修改帳戶資訊",
  EditBankInfoForm_account: "銀行帳號",
  EditBankInfoForm_name: "戶名",
  EditBankInfoForm_bank: "銀行代碼",
  EditBankInfoForm_city: "所在省市",
  EditBankInfoForm_confirm: "確定",
  EditBankInfoForm_error: "*無效的輸入",

  //** Web Push */
  web_push_button_allow: "通知已開啟",
  web_push_button_deny: "通知已關閉",

  web_push_open_notify_request: "請先開啟瀏覽器通知",
  web_push_not_support: "你的瀏覽器不支援 Notification",

  // ===========
  //  Instant
  // ============
  instant_name: '姓名',
  instant_timer: '發起時間',
  instant_appeal: '申訴中'
};
